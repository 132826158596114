<template>
  <CRow>
    <CCol col="12" lg="8">
      <CCard style="max-width: 40rem;">
        <CCardHeader>
          Create Customer
        </CCardHeader>
        <CCardBody>
          <CAlert :show.sync="customerCreated" closeButton color="success">
            Customer {{ customer.name }} created
          </CAlert>
          <CAlert :show.sync="customerCreateFailed" closeButton color="danger">
            Create Customer {{ customer.name }} failed
          </CAlert>
          <CCardBody class="p-4">
            <CForm>
              <CInput
                  label="Name"
                  placeholder="Customer Name"
                  v-model="customer.customer_name"
                  valid-feedback="Customer name is valid."
                  invalid-feedback="Minimum length is 4 and only characters and spaces."
                  description="The Name can be changed afterwards."
                  :is-valid="!$v.customer.customer_name.$invalid"  
                  :disabled="inputDisabled"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>

                <CInput
                  label="Email"
                  placeholder="Customer Email"
                  v-model="customer.customer_email"
                  valid-feedback="Customer email is valid."
                  invalid-feedback="Email is not a valid email format."
                  description="The Email can't be changed afterwards."
                  :is-valid="!$v.customer.customer_email.$invalid"  
                  :disabled="inputDisabled"
                >
                  <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>

                <CTextarea
                  label="Description"
                  v-model="customer.customer_desc"
                  :value.sync="customer.customer_desc"       
                  :is-valid="!$v.customer.customer_desc.$invalid"  
                  invalid-feedback="Description is not valid."
                  />                                

            </CForm>
          </CCardBody>
        </CCardBody>
        <CCardFooter>
          <CButton color="light" class="col-sm-2" @click="goBack">Back</CButton>
          <CButton
            v-on:click="newCustomer"
            color="success"
            class="float-right col-sm-4"
            v-show="!nextDisabled"
            :disabled="nextDisabled"
          >
            Next Customer
          </CButton>
          <CButton
            v-on:click="createCustomer"
            color="success"
            class="float-right col-sm-4"
            v-show="!inputDisabled"
            :disabled="$v.$invalid || inputDisabled"
          >
            Create Customer
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import CustomerService from '@/services/customer.services';
import { required, email, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^([a-zA-Z0-9-_\\.&!#=()\\s])*$/i);
const customerDescValidator = helpers.regex('alphaNumAndDot', /^([a-zA-Z0-9- _\,.&!#=\\\/()\s]){0,}$/i);

export default {
  data() {
    return {
      customer: {
        customer_name: '',
        customer_desc: '',
        customer_email: '',
      },
      customerCreated: false,
      inputDisabled: false,
      nextDisabled: true,
      customerCreateFailed: false,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    customer: {
      customer_name: {
        minLength: minLength(4),
        maxLength: maxLength(32),
        required,
        alphaNumAndDotValidator,
        $autoDirty: true
      },
      customer_email: {
        required,
        email
      },
      customer_desc: {
        minLength: minLength(0),
        maxLength: maxLength(2048),        
        customerDescValidator,
        $autoDirty: true
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push({ path: '/customers' });
    },
    newCustomer() {
      this.customer = {
        customer_name: '',
        customer_desc: '',
        customer_email: '',
      };
      this.customerCreated = false;
      this.inputDisabled = false;
      this.nextDisabled = true;
    },
    async createCustomer() {
      try {
        const response = await CustomerService.createCustomer(
          this.customer.customer_name,
          this.customer.customer_email,
          this.customer.customer_desc,
          this.$cognitoAuth.getCurrentUser().username
        );
        await this.createCustomerCredentials(response.customer_id);

        this.customerCreated = true;
        this.inputDisabled = true;
        this.nextDisabled = false;
      } catch (error) {
        console.error(error);
        this.customerCreateFailed = true;
      }
    },
    async createCustomerCredentials(id) {
      try {
        await CustomerService.createCustomerCredentials(id);
      } catch (error) {
        console.error(error);
        this.customerCreateFailed = true;
      }
    },
  },
};
</script>
